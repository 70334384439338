@import colours
html
    padding: 0
    margin: 0
body
    padding: 0
    margin: 0
    overflow-x: hidden
    background-color: #efefef!important

    *
        box-sizing: border-box

    a
        text-decoration: none!important

    .link
        &:hover
            cursor: pointer

    .secondary-button
        color: $white
        background-color: $green

        &:hover
            color: $white
            background-color: $hoverGreen

    .navbar__wrapper
        min-height: 60px
        max-height: 45px

        .logout-link
            &:hover
                cursor: pointer
                background-color: #fdf7f71a

    main
        min-height: max-content
        margin-bottom: 10vh
        margin-left: 73px
        .home
            margin: 10vh 6vw 10vh

            .upload__container
                background-color: #ffff !important
                .upload__img
                    img
                        max-width: 100%
                        height: auto
            .thumbnail__container
                max-height: 100%
                max-width: 100%
                img
                    max-height: 80%
                    max-width: 80%
        .history
            margin: 0 6vw
            margin-left: -2vw
            table
                box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%)
                font-size: 0.8rem
                width: 100%
                border-radius: 10px

                thead
                    border-bottom: 1px solid rgba(0,0,0,0.05)

                th
                    padding: 5px 5px
                    border-radius: 0px
                    text-align: center
                    background-color: #b1c5e733
                    color: black
                    // border-radius: 10px
                th:nth-child(1)
                    border-top-left-radius: 10px
                th:nth-child(4)
                    border-top-right-radius: 10px

                tbody
                    border-radius: 2px

                    td
                        text-align: left

                    td:last-child
                        min-width: 130px
                        border-right: none

                    .cell
                        padding: 10px

                    tr
                        // background-color: white

                    tr:nth-child(even)
                        background-color: #b1c5e733
                    tr:nth-child(2n+1)
                        background-color: white
                    tr:last-child
                        border: none
                        height: 50px
                        td:last-child
                            background-color: transparent
                            border: none

        .btn
            font-size: 12px
            border-radius: 10px
            box-shadow: 5px 4px 30px rgba(0,0,0,.10)

        .btn-page
            background-color: $green
            color: $white
            font-size: 12px
            min-height: 32px
            border-radius: $buttonBorderRadius
            border: 0 !important

        .btn-action
            background-color: rgb(0, 122, 222)
            color: $white
            font-size: 12px
            min-height: 32px
            border-radius: $buttonBorderRadius
            border: 0 !important

        .btn-action:hover
            background-color: #0172ce

        .btn:focus

            background-color: $white
            border-color: #80bdff
            outline: 0
            box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%)

        .btn-pagination
            min-width: 60px
            border-radius: $buttonBorderRadius
            //background-color: $navbarBlue
            //color: $white
            border: 0
        .btn-pagination-disabled
            min-width: 60px
            border-radius: $buttonBorderRadius

        .throughputReport
            margin-top: 5vw

            table
                box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%)
                font-size: 0.8rem
                width: 100%
                border-radius: 10px

                thead
                    border-bottom: 1px solid rgba(0,0,0,0.05)

                th
                    padding: 5px 5px
                    border-radius: 0px
                    text-align: center
                    background-color: #b1c5e733
                    color: black
                    // border-radius: 10px
                th:nth-child(1)
                    border-top-left-radius: 10px
                th:nth-child(4)
                    border-top-right-radius: 10px

                tbody
                    border-radius: 2px

                    td
                        text-align: left

                    td:last-child
                        min-width: 130px
                        border-right: none

                    .cell
                        padding: 10px

                    tr
                        // background-color: white

                    tr:nth-child(even)
                        background-color: #b1c5e733
                    tr:nth-child(2n+1)
                        background-color: white
                    tr:last-child
                        border: none
                        height: 50px
                        td:first-child
                            background-color: transparent
                            border: none
                            border-bottom-left-radius:10px
                        td:last-child
                            background-color: transparent
                            border: none
                            border-bottom-right-radius:10px

        .setClient
            .css-1in441m
                width: 68%
