$white: #FFFFFF
$backgroundGrey: #F7F7F7
$navbarBlue: #305697
$borderGrey: #E8E9EC
$bottomBorderGrey: #9e9e9e
$textGrey: #4D4F5C90
$bulletGrey: #EDECFE
$red: #DF426B
$focusBlue: #80BDFF
$green: #4caf50
$hoverGreen: #3e9241
$highlightGreen: #54f542

$buttonBorderRadius: 10px
$letterSpacing: 0.1rem
$boxRadius: 1rem
$inputBoxRadius: 1rem
$boxShadow: 5px 4px 30px rgba(0,0,0,.10)
$inputBoxShadow: 0 0 0 0.2rem rgb(0 123 255 / 25%)
