.login-page
    background-color: #f0f2f5
.logo-container
    border-radius: 5px
    position: absolute
    top: -10px
    left: 20px
    justify-content: center
    // background: white
    // box-shadow: 3px 3px 6px #bfc3cf, -3px -3px 6px #fff

.logo
    border: 0px !important
    background-color: transparent
    // color: white

.main-login-container
    width: 100vw
    display: flex
    align-items: center
    justify-content: space-around
    padding-top: 70px
    // background: url("../assets/loginFormBackground.svg")
    background-position: right
    background-repeat: no-repeat
    background-size: contain

.login-image-container
    min-width: 60vw
    height: 90vh
    background: url("../assets/images/login.svg")
    background-position: center
    background-repeat: no-repeat
    background-size: 110%
    border-color: #df426b
    border: 0px

.login-form-container
    border-radius: 12px
    margin-right: 10%
    width: 25vw
    min-height: 40vh
    max-height: 20%
    display: flex
    flex-direction: column
    justify-content: center
    background-color: white
    color: #000
    box-shadow: 0px 3px 6px 2px #bfc3cf
    padding: 20px 20px 30px 20px

    .product-name-container
        margin-bottom: 10px

        img
            padding: 5px
            margin-right: 10px

        h3
            text-align: center
            font-weight: 400

    .login-error
        display: block
        width: 100%
        margin: 0px
        color: #df426b

    .warning-container
        position: relative

        .warning-trigger
            cursor: pointer
            color: #007ade
            text-decoration: underline
            font-size: 0.8rem

        .warning-message
            display: none
            transition: all 0.2s ease-in-out
            position: absolute
            top: 18px
            left: 0
            width: 100%
            color: #df426b

    .signin-button
        width: 100%
        border-radius: 5px !important
        background-color: #01448e
        margin-top: 50px
        color: white
